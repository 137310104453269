<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <!--<el-breadcrumb separator="/">
				<el-breadcrumb-item>系统管理</el-breadcrumb-item>
				<el-breadcrumb-item>系统用户管理</el-breadcrumb-item>
			</el-breadcrumb>-->
      <el-button class="right" size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <!--主体内容-->
    <div class="content bgFFF">
      <TitleModule :title="'商户管理员详情'"></TitleModule>
      <el-form
        label-position="left"
        label-width="100px"
        :model="formInline"
        style="width: 400px; float: left; margin-left: 0"
      >
        <el-form-item label="商户:" prop="operationName" style="margin-bottom: 0">
          <span>{{ formInline.operationName }}</span>
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile" style="margin-bottom: 0">
          <span>{{ formInline.mobile }}</span>
        </el-form-item>
        <el-form-item label="登录名:" prop="account" style="margin-bottom: 0">
          <span>{{ formInline.account }}</span>
        </el-form-item>
        <el-form-item label="姓名:" prop="operationManagerName" style="margin-bottom: 0">
          <span>{{ formInline.operationManagerName }}</span>
        </el-form-item>
        <el-form-item label="邮箱:" prop="" style="margin-bottom: 0">
          <span>{{ formInline.email }}</span>
        </el-form-item>
        <el-form-item label="性别:" prop="sex" style="margin-bottom: 0">
          <span>{{ formInline.sex == 1 ? "男" : "女" }}</span>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {} from "@/common/js/public.js";
import TitleModule from "@/components/titleModule/titleModule";
export default {
  name: "hello",
  data() {
    let checkRoleName = (rule, value, callback) => {
      let opt = {
        url: "/boss/2.0/system/checkRoleName",
        method: "post",
        data: {
          systemRoleName: value,
        },
        success: (res) => {
          if (res.state == 12 && this.oldName == value) {
            callback();
          } else if (res.state == 12) {
            callback(new Error("该角色名称已经存在"));
          } else if (res.state == 13) {
            callback();
          } else {
            callback(new Error(res.desc));
          }
        },
      };
      this.$request(opt);
    };
    return {
      isEdit: false,
      treeList: [],
      oldName: "",
      resourceList: [],
      defaultProps: {
        children: "children",
        label: "resourceName",
      },
      formInline: {
        systemRoleName: "",
        resourceIds: "",
        description: "",
        systemType: 3,
      },
      rules: {
        systemRoleName: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
          {
            validator: checkRoleName,
            trigger: "blur",
          },
        ],
        systemType: [
          {
            required: true,
            message: "请选择平台",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    queryDetail() {
      this.$axios
        .get("/acb/2.0/operationManager/getbyid/" + this.$route.query.operationManagerId)
        .then((res) => {
          this.formInline = res.value;
        });
    },
    submitData() {
      if (sessionStorage.resourceIds) {
        this.formInline.resourceIds = sessionStorage.resourceIds;
      }
      if (!this.formInline.description) {
        this.formInline.description = "";
      }
      let opt = {
        url: this.isEdit
          ? "/boss/2.0/supervisorRoles/update/" + this.$route.query.operationManagerId
          : "/boss/2.0/supervisorRoles/create",
        method: "post",
        data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              type: "info",
              message: "操作成功",
            });
            sessionStorage.resourceIds = "";
            sessionStorage.juese = "";
            this.$router.go(-1);
            sessionStorage.searchFlag = 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!sessionStorage.resourceIds && !this.formInline.resourceIds) {
            this.$alert("没有配置权限", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.$request(opt);
        } else {
          return false;
        }
      });
    },
    setResourse() {
      sessionStorage.juese = JSON.stringify(this.formInline);
      if (this.isEdit) {
        this.$router.push({
          path: "/configPermiss",
          query: this.$route.query,
        });
      } else {
        this.$router.push({
          path: "/configPermiss",
        });
      }
    },
  },
  components: {
    TitleModule,
  },
  created() {
    if (sessionStorage.juese) {
      this.formInline = JSON.parse(sessionStorage.juese);
      sessionStorage.juese = "";
    }
  },
  mounted() {
    if (this.$route.query.operationManagerId) {
      this.isEdit = true;
      this.oldName = this.$route.query.systemRoleName;
      this.queryDetail();
    }
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content

  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
</style>
